import React, { useState } from "react";
import PopupLayout from "../../components/templetes/popupLayout";
import { StyledButton } from "../../components/atoms/button";
import { ValidationInput } from "../../components/atoms/validation/ValidationInput";
import { StyledButtonArea } from "../../components/molecules/buttonArea";
import { useSelector } from "react-redux";

const ForgotUserIDPopup = ({ findUserID, hasUserName, closePopup }) => {
  const { userIds } = useSelector((store) => store.auth.forgotPopup);
  const [validate, setValidate] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [page, setPage] = useState(0);

  const onSubmit = () => {
    setValidate(true);
    if(hasUserName){
      if (name === "" || email === "") return;
    }else{      
      if (email === "") return;
    }
    findUserID(
      {
        username: name,
        email: email,
      },
      () => {
        setPage(1);
      }
    );
  };

  return (
    <>
      <PopupLayout
        theme="primary"
        data-size="lg"
        title="Find User ID"
        closePopup={closePopup}
        button={
          <StyledButtonArea>
            {page === 0 && (
              <StyledButton
                type="text"
                data-bg="primary"
                size="md"
                onClick={onSubmit}
              >
                Find
              </StyledButton>
            )}
          </StyledButtonArea>
        }
      >
        {page === 0 && (
          <div style={{ paddingLeft: "3%", paddingRight: "3%" }}>
            {hasUserName&&(<ValidationInput
              label="Name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              showValidate={validate}
              invalidMsg="name is required."
            />)}
            <ValidationInput
              label="E-Mail"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              showValidate={validate}
              invalidMsg="E-Mail is required."
            />
          </div>
        )}
        {page === 1 && (
          <div
            style={{
              paddingLeft: "3%",
              paddingRight: "3%",
              marginTop: "3%",
              minHeight: "20px",
              textAlign: "center",
            }}
          >
            <p>Your ID is</p>
            {userIds.map((userId, index) => (
              <span key={index} style={{ color: "blue", display: "block" }}>
                {userId}
              </span>
            ))}
          </div>
        )}
      </PopupLayout>
    </>
  );
};

export default ForgotUserIDPopup;

import React, { useEffect, useState } from "react";
import {
  changeUserPw,
  closePwChangePopup,
  findUserID,
  getIntroConfig,
  onLoginConnect,
  onLoginWEdi,
  resetPassword,
  onLoginAPIGateway,
  registerApiUser
} from "../AuthReducer";
import IntroLayout from "../../components/templetes/introLayout";
import { useDispatch, useSelector } from "react-redux";
import LoginForm from "../../components/organisms/loginForm";
import { TabArea } from "../../components/molecules/tab";
import PopupLayout from "../../components/templetes/popupLayout";
import { StyledButtonArea } from "../../components/molecules/buttonArea";
import { StyledButton } from "../../components/atoms/button";
import { ValidationInput } from "../../components/atoms/validation/ValidationInput";
import Validation from "../../components/atoms/validation/Validation";

const CONNECT_USER_TYPE = "connect";
const WEDI_USER_TYPE = "wedi";
const APIGW = "apigw"
const IntroContainer = (props) => {
  const dispatch = useDispatch();
  const { introConfig, userId, pwChangePopupFlag } = useSelector(
    (state) => state.auth
  );
  const [tabMode, setTabMode] = useState(localStorage.getItem("tab_mode"));
  const [connectUserID, setConnectUserID] = useState(localStorage.getItem("connect_user_ID"));
  const [wediUserID, setWediUserID] = useState(localStorage.getItem("wedi_user_ID"));
  const [apiUserID, setApiUserID] = useState(localStorage.getItem("api_user_ID"));
  const [showRegisterApiGwUserPopup, setShowRegisterApiGwUserPopup] = useState(false)
  const [rememberUserId, setRememberUserId ] = useState(localStorage.getItem("remember_user_ID"));

  useEffect(() => {
    dispatch(getIntroConfig());
    if (localStorage.getItem("tab_mode") == null) 
      setTabMode(CONNECT_USER_TYPE)
    if (localStorage.getItem("remember_user_ID") == null||localStorage.getItem("remember_user_ID") == false){ 
      setRememberUserId(false)
      setConnectUserID("");
      setWediUserID("");
      setApiUserID("");
      return;
    }  
    if(localStorage.getItem("connect_user_ID") == null)
      setConnectUserID("");
    if(localStorage.getItem("wedi_user_ID") == null)
      setWediUserID("");
    if(localStorage.getItem("api_user_ID") == null)
      setApiUserID("");

  }, []);

  return (
    introConfig && (
      <IntroLayout config={introConfig}>
        <TabArea
          tabData={[
            {
              key: CONNECT_USER_TYPE,
              name: "Connect",
              content: (
                <LoginForm
                  onLogin={(req, redirect) =>
                    dispatch(onLoginConnect(req, redirect, rememberUserId))
                  }
                  title="CONNECT LOGIN"
                  config={introConfig}
                  findUserID={(req, cb) =>
                    dispatch(findUserID(req, CONNECT_USER_TYPE, cb))
                  }
                  resetPassword={(req, cb) =>
                    dispatch(resetPassword(req, CONNECT_USER_TYPE, cb))
                  }
                  changeUserPw={(req, cb) =>
                    dispatch(
                      changeUserPw(
                        {
                          ...req,
                          userId: userId,
                        },
                        CONNECT_USER_TYPE,
                        cb
                      )
                    )
                  }
                  closePwChangePopup={() => dispatch(closePwChangePopup())}
                  onClickSignUp={() => window.location.replace(introConfig.introSignupUrl)}
                  pwChangePopupFlag={pwChangePopupFlag}
                  localStorageId={connectUserID}
                  setRememberUserId={(rememberUserId)=>{
                    setRememberUserId(rememberUserId)
                    localStorage.setItem("remember_user_ID", rememberUserId)
                  }}
                  rememberUserId={rememberUserId}
                />
              ),
            },
            {
              key: WEDI_USER_TYPE,
              name: "Web EDI",
              content: (
                <LoginForm
                  onLogin={(req, redirect) =>
                    dispatch(onLoginWEdi(req, redirect, rememberUserId))
                  }
                  title="WEB-EDI LOGIN"
                  config={introConfig}
                  findUserID={(req, cb) =>
                    dispatch(findUserID(req, WEDI_USER_TYPE, cb))
                  }
                  resetPassword={(req, cb) =>
                    dispatch(resetPassword(req, WEDI_USER_TYPE, cb))
                  }
                  changeUserPw={(req, cb) =>
                    dispatch(
                      changeUserPw(
                        {
                          ...req,
                          userId: userId,
                        },
                        WEDI_USER_TYPE,
                        cb
                      )
                    )
                  }
                  closePwChangePopup={() => dispatch(closePwChangePopup())}
                  pwChangePopupFlag={pwChangePopupFlag}
                  onClickSignUp={() => window.location.replace(introConfig.introSignupUrl)}
                  localStorageId={wediUserID}
                  setRememberUserId={(rememberUserId)=>{
                    setRememberUserId(rememberUserId)
                    localStorage.setItem("remember_user_ID", rememberUserId)
                  }}
                  rememberUserId={rememberUserId}
                />
              ),
            },
            {
              key: APIGW,
              name: "API",
              content: (
                <>

                  <LoginForm
                    hasUserName = {false}
                    onLogin={(req, redirect) =>
                      dispatch(onLoginAPIGateway(req, redirect, rememberUserId))
                    }
                    title="API Gateway LOGIN"
                    config={introConfig}
                    findUserID={(req, cb) =>
                      dispatch(findUserID(req, APIGW, cb))
                    }
                    resetPassword={(req, cb) =>
                      dispatch(resetPassword(req, APIGW, cb))
                    }
                    changeUserPw={(req, cb) =>
                      dispatch(
                        changeUserPw(
                          {
                            ...req,
                            userId: userId,
                          },
                          APIGW,
                          cb
                        )
                      )
                    }
                    closePwChangePopup={() => dispatch(closePwChangePopup())}
                    pwChangePopupFlag={pwChangePopupFlag}
                    onClickSignUp={() => setShowRegisterApiGwUserPopup(true)}
                    localStorageId={apiUserID}
                    setRememberUserId={(rememberUserId)=>{
                      setRememberUserId(rememberUserId)
                      localStorage.setItem("remember_user_ID", rememberUserId)
                    }}
                    rememberUserId={rememberUserId}
                  />
                  {
                    showRegisterApiGwUserPopup &&
                    <APIGatewayUserRegisterForm
                      onClosePopup={() => setShowRegisterApiGwUserPopup(false)}
                      onConfirm={(user) => dispatch(registerApiUser(user, () => {
                        alert(`User '${user.userId}' is created successfully.`)
                        setShowRegisterApiGwUserPopup(false)
                      }))}
                    />
                  }

                </>
              ),
            },
          ]}
          setMode={(mode) => {
            setTabMode(mode)
            localStorage.setItem("tab_mode", mode)
          }}
          mode={tabMode}
        />

      </IntroLayout>
    )
  );
};
export default IntroContainer;

const APIGatewayUserRegisterForm = ({
  onConfirm = () => { },
  onClosePopup
}) => {
  const [validation, setValidation] = useState(false)
  const [userId, setUserId] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [passwordConfirm, setPasswordConfirm] = React.useState("")
  const [email, setEmail] = React.useState("");
  return (
    <PopupLayout
      theme="primary"
      data-size="lg"
      title="Register API User"
      closePopup={onClosePopup}
      button={
        <StyledButtonArea>
          <StyledButton
            type="text"
            data-bg="primary"
            size="md"
            onClick={() => {
              if (userId == "" || password == "" || passwordConfirm == "") {
                setValidation(true)
                return;
              }
              onConfirm({
                userId: userId,
                password: password,
                passwordConfirm: passwordConfirm,
                email: email
              })
            }}
          >
            Confirm
          </StyledButton>
        </StyledButtonArea>
      }
    >
      <Validation>
        <ValidationInput
          label="User ID"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          invalidMsg={"UserId is requred"} showValidate={validation} />
        <ValidationInput label="Password" type="password" value={password}
          onChange={e => setPassword(e.target.value)}
          invalidMsg="Password is Mandatory"
          showValidate={validation} />
        <ValidationInput
          label="Password (Confirm)"
          type="password"
          invalidMsg="Check Your Password"
          value={passwordConfirm}
          onChange={(e) => setPasswordConfirm(e.target.value)}
          showValidate={validation}
        />
        <ValidationInput label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}

        />
      </Validation>
    </PopupLayout>
  )
}